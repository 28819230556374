.Political{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
}
.form{
    height: 100%;
    width: 90%;
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-behavior: smooth;
    padding: 20px 0;
}

.form > form{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    
}
.form >form >input{
    padding: 8px 15px;
    font-size: 18px;
    background-color: whitesmoke;
    outline: none;
    border: none;
    border-radius: 10px;
    transition: 0.4s;
}
.form>form>input:focus-within,textarea:focus{
    border: 1px solid rgb(202, 199, 199);
    transition: 0.4s;
}  
.form>form>h2{
    padding: 5px 10px;
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: left;
    background-color: rgb(224, 236, 247);
    border-left: 2px solid dodgerblue;
    
}
.form >form>h2>img{
    height: 50px;
}
.form >form>h5{
    letter-spacing: 1px;
    
}
textarea{
    height: 200px;
    font-size: 18px;
    padding: 20px;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: whitesmoke;
}
.form >form >Button{
    margin-top: 20px;
    letter-spacing: 2px;
    
}
.bacarr{
    margin-top: 15px !important;
    float: right;
    padding: 5px;
    background-color: black !important;
}
.bacarr1{
   font-size: 25px !important;
   color: white;
}
.popale{
    position: absolute;
    z-index: 2;
    background-color: rgba(170, 165, 165, 0.678);
    display: flex;
    height: 100%;
    width: 100%;
    bottom: 0;
    margin-top: 650px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.popcard{
    width: 450px;
    height: 300px;
    padding: 15px 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    animation: popo 0.5s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.popcard > p{
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 10px 0;
}
.popcard >img{
    margin-top: 10px;
    height: 50px;
}
.neico{
     height: 150px !important;
     margin-bottom: 10px;
}
@keyframes popo {
    0% {
        scale: 0;
    }

    100% {
        scale: 1;
    }
}